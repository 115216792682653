import React from "react";
import {Select} from "antd";
import { getReportInformationById, reportsArray} from "../../../managers/reportManager";
import CustomSelect from "../../common/selects/Select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const {Option} = Select;

const ReportSelect = props => {
    const me = useSelector((state) => state.users.me);
    const { t } = useTranslation();
  
    const {onChange, value} = props;
    
    return (
        <CustomSelect value={value}
                      allowClear={false}
                      handleChange={onChange}
                      placeholder="Fahrtenprotokoll">
            {reportsArray.map(r => {
                const { id } = r;
                const report = getReportInformationById(id);
      
                if(report.hasPermission && !report.hasPermission(me)) {
                  return;
                }
                 return <Option key={r.id} value={r.id}>
                    {t(r.translationKey)}
                </Option>
            })}
        </CustomSelect>
    );
};

ReportSelect.defaultProps = {};

export default ReportSelect;
